import numeral from 'numeral';
import { mapGetters } from 'vuex';
import registerNumeralLocale from './registerNumeralLocale';

export default {
  mixins: [registerNumeralLocale],
  data() {
    return {
      dataTypes: {
        impressions: 'number',
        conversionRate: 'percentage',
        goalConversion: 'number',
        conversions: 'number',
        visitors: 'number',
        visitorsWithOrders: 'number',
        orders: 'number',
        totalOrders: 'number',
        totalOrdersHeader: 'number',
        assistedOrders: 'number',
        assistedRevenue: 'currency',
        revenue: 'currency',
        impressionValue: 'currency',
        aov: 'currency',
        uplift: 'percentage',
        chanceToWin: 'percentage',
        revenueUplift: 'percentage',
      },
      formats: {
        percentage: '0,0.00%',
        number: {
          default: '0,0',
          shortM: '0.0a',
        },
        currencyOnAnalytics: {
          default: '0,0.[00]',
          shortM: '0,0.[00]a',
        },
        currency: {
          en: {
            default: '$0,0.[00]',
            shortK: '$0.[0] a',
            shortM: '($0.[00] a)',
          },
          hu: {
            default: '0,0.[00] $',
            shortK: '0.0 a $',
            shortM: '(0.00 a $)',
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(['getLocale']),
  },
  methods: {
    formatNumber({ value, key, currency, isLongFormat, usedOnAnalyticsPage }) {
      const lang = this.getLocale;
      let result;

      const dataType = this.dataTypes[key];

      if (dataType === 'number') {
        if (value === this.$t('analyticsPage.baseline')) {
          return value;
        }
        if (value === 'N/A') {
          return value;
        }
        if (isLongFormat || value < 1000000) {
          result = this.formatNumberWithNumeral(value, this.formats.number.default);
        } else {
          result = this.formatNumberWithNumeral(value, this.formats.number.shortM);
        }
      } else if (dataType === 'percentage' && usedOnAnalyticsPage) {
        if (value === this.$t('analyticsPage.baseline') || value === 'N/A') {
          return value;
        }
        if (
          (key === 'chanceToWin' || key === 'uplift') &&
          (typeof value === 'undefined' || value === null)
        ) {
          result = '';
        } else {
          result = this.formatNumberWithNumeral(value, this.formats.percentage);
        }
        if (['uplift', 'revenueUplift'].includes(key) && value > 0) {
          result = `+${result}`;
        }
        // used on Analytics page
      } else if (dataType === 'percentage') {
        result = this.formatNumberWithNumeral(value / 100, this.formats.percentage);
      } else if (dataType === 'currency' && usedOnAnalyticsPage) {
        // used on Analytics page
        if (value === 0 && key === 'aov') {
          result = 'N/A';
        } else if (isLongFormat || value < 1000000) {
          result = `${this.formatNumberWithNumeral(
            value,
            this.formats.currencyOnAnalytics.default,
          )} ${currency}`;
        } else {
          result = `${this.formatNumberWithNumeral(
            value,
            this.formats.currencyOnAnalytics.shortM,
          )} ${currency}`;
        }
      } else if (dataType === 'currency' && !usedOnAnalyticsPage) {
        if (value === 0) {
          result = 'N/A';
        } else if (isLongFormat) {
          result = this.formatNumberWithNumeral(value, this.formats.currency[lang].default);
        } else if (value > 1000000) {
          result = this.formatNumberWithNumeral(value, this.formats.currency[lang].shortM);
        } else if (value > 10000) {
          result = this.formatNumberWithNumeral(value, this.formats.currency[lang].shortK);
        }
      }

      return result;
    },

    formatNumberWithNumeral(value, format) {
      return numeral(value).format(format);
    },
  },
};
